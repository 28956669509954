<template>
  <div>
    <!--  注册证 -->
    <div class="fromDiv">
      <div class="tableTitle">
        <span class="title">
          注册证
        </span>
         <div class="overflow">
          <div class="over">
            <span class="remarksAlink" >国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧选择国产器号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的注册证号，然后打开注册证信息复制粘贴即可完成信息维护。 <span class="marginL" >国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧械（注册证号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的注册证号，然后打开注册证信息复制粘贴即可完成信息维护。</span></span> 
          </div>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="注册证" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件" disabled v-model="postFrom.licence_file_name">
            <span slot="append" class="finger">
              <newformUpload label="上传" @get-key="getKey" :data="data"></newformUpload>
            </span>
          </Input>
          <span class="lookupImg" v-if="postFrom.licence_file_key && postFrom.licence_code" @click="openFile">{{ postFrom.licence_file_name }}-注册证</span>
          <span class="lookupImg" v-else-if="postFrom.licence_file_key && !postFrom.licence_code" @click="openFile">-注册证</span>
          <img v-if="postFrom.licence_file_name" class="marginLeft20 finger" @click="delectImg" src="../../../assets/images/deleteLogistics.png" />
        </div>
        <FromLabel label="产品分类"  ></FromLabel>
        <div class="formFrIpt fl">
          <Select v-model="postFrom.licence_product_categories"  class="iviewIptWidth307">
              <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <!-- <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_code" :readonly="status != 3 && status != 2"></i-input> -->
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="注册证编号" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input @on-change="changeNum" placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.licence_code"></i-input>
        </div>
        <FromLabel label="生产地址" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.production_address"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="注册人名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.creator_company_name"></i-input>
        </div>
        <FromLabel label="注册人住所" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.creator_company_address"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="代理人名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.proxy_name"></i-input>
        </div>
        <FromLabel label="代理人住址"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.proxy_address"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="结构及组成" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.comprise_desc"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="适用范围" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.application_scope"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="批准日期" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" :value="postFrom.registrant_time" class="iviewIptWidth307" @on-change="changeTime($event, 1)"></DatePicker>
        </div>
        <FromLabel label="有效期至" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" :value="postFrom.end_time" class="iviewIptWidth307" :options="options" @on-change="changeTime($event, 2)"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="审批部门"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.approve_dept"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="备注"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.licence_remark"></i-input>
        </div>
      </div>
    </div>
    <div class="tableTitle">
      <span class="title">
        规格型号
      </span>
      <!-- <span class="longTextBtn finger btnReset fr" @click="addBill"> 模板导入</span>
      <span class="longTextBtn finger btnReset fr marginRight18" @click="downloadTemplate"> 模板下载 </span> -->
      <span class="longTextBtn btnSure fr marginRight18 finger" @click="addSku"> 新增 </span>
    </div>
       <ux-grid border
             :rules="rules" 
             :height="height"
             show-overflow="true"
             ref="plxTable"
             widthResize
             @table-body-scroll="scroll"
             style="width: 100%; position: relative"
             :edit-config="{trigger: 'click', mode: 'cell'}">
              <ux-table-column type="index" align="center" title="序号" width="60" ></ux-table-column>
             <ux-table-column field="product_model_code" align="center" title="产品编号"  width="150">
                <template v-slot="scope">
                  <span class="textLeft onlyonce">{{ scope.row.product_model_code }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="product_code_child" align="center" title="子编号" width="150">
                <template v-slot="scope">
                  <span class="textLeft onlytwice">{{ scope.row.product_code_child }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="model_name" align="center" title="规格型号" width="150" :edit-render="{autofocus: '.ivu-input'}">
                <template v-slot:edit="scope">
                  <i-input v-model="scope.row.model_name"  @on-change="changeModelnName($event, scope.rowIndex)"  class="onlythird1" :disabled="scope.row.is_approved == '1'" placeholder="请输入" type="text"></i-input>
                </template>
                <template v-slot="scope">
                  <span class="my-input-sc nowrap textLeft onlythird">{{ scope.row.model_name }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="item_number" align="center" title="货号/SKU" width="150" :edit-render="{autofocus: '.ivu-input'}">
                <template v-slot:edit="scope">
                  <i-input v-model="scope.row.item_number" @on-change="changeItemNum($event, scope.rowIndex)" class="onlythird2" placeholder="请输入" type="text" ></i-input>
                </template>
                <template v-slot="scope">
                  <span class="my-input-sc nowrap textLeft onlyforth">{{ scope.row.item_number }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="unit_price" align="center" title="单价" width="150" :edit-render="{autofocus: '.ivu-input-number-input'}">
                <template v-slot:edit="scope">
                  <InputNumber :active-change="false" class="onlythird3" :max="9999999" :min="0" style="height:38px;width:100%;"  v-model="scope.row.unit_price" placeholder="请输入" @on-blur="notax_unit_priceChange(scope.row,scope.rowIndex)"></InputNumber>
                </template>
                <template v-slot="scope">
                  <span class="my-input-sc nowrap onlyififth">{{ scope.row.unit_price }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="tax_rate" align="center" title="税率(%)" width="150" :edit-render="{autofocus: '.ivu-input-number-input'}">
                <template v-slot:edit="scope">
                 <InputNumber :active-change="false" class="onlythird4" :max="100" :min="0" style="height:38px;" :precision="0" v-model="scope.row.tax_rate" @on-blur="notax_unit_priceChange(scope.row,scope.rowIndex)" placeholder="请输入"></InputNumber>
                </template>
                <template v-slot="scope">
                  <span class="my-input-sc nowrap onlysixth">{{ scope.row.tax_rate }}</span>
                </template>
             </ux-table-column>
            <ux-table-column field="notax_unit_price" align="center" title="不含税单价" width="150">
                <template v-slot="scope">
                  <span class="bludColor onlyseventh">{{ scope.row.notax_unit_price  }}</span>
                </template>
             </ux-table-column>
             <ux-table-column field="unit" align="center" title="最小包装规格" width="380" :edit-render="{autofocus: '.ivu-input-number-input'}">
               <template v-slot:edit="scope">
                <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="scope.row.unit.qty" placeholder="请输入"   class="iviewIptWidth100 marginRight18 onlyseventh2"></InputNumber>
                <Select class="iviewIptWidth100 onlyseventh3"  placeholder="请选择" filterable v-model="scope.row.unit.unit"  allow-create clearable ref="unitqty" @on-change="changeUnitUnit(scope.row.unit.unit, 'unit', 'unit', scope.rowIndex)"  @on-create="handleCreateUnit">
                  <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
                </Select>
                <span class="xiegang">/</span>
                <Select class="iviewIptWidth100 onlyseventh4"  placeholder="请选择" filterable v-model="scope.row.unit.dose_unit" @on-change="changeUnitDose(scope.row.unit.dose_unit,scope.rowIndex)"  allow-create clearable ref="unitqty"  @on-create="handleCreateUnit">
                  <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
                </Select>
                <span class="spin">(单位)</span>
               </template>
               <template v-slot="scope">
                 <span class="onlyseventh1">{{scope.row.unit.qty}}　{{scope.row.unit.unit}}<span class="xiegang">/</span>{{scope.row.unit.dose_unit}}</span>
               </template>
             </ux-table-column>
             <ux-table-column field="packing_size" align="center" title="包装规格" width="380" :edit-render="{autofocus: '.ivu-input-number-input'}">
               <template v-slot:edit="scope">
                <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="scope.row.packing_size.qty" placeholder="请输入"   class="iviewIptWidth100 marginRight18 onlyeight"></InputNumber>
                <Select class="iviewIptWidth100 onlyeight1"  placeholder="请选择" filterable v-model="scope.row.packing_size.unit"  allow-create clearable ref="unitqty"  @on-create="handleCreateUnit">
                  <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
                </Select>
                <span class="xiegang">/</span>
                <Select class="iviewIptWidth100 onlyeight2"  placeholder="请选择" filterable v-model="scope.row.packing_size.dose_unit" @on-change="changePacDose(scope.row.unit.dose_unit,scope.rowIndex)"  allow-create clearable ref="unitqty"  @on-create="handleCreateUnit">
                  <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
                </Select>
                <span class="spin">(单位)</span>
               </template>
               <template v-slot="scope">
                 <span class="onlyeight3">{{scope.row.packing_size.qty}}　{{scope.row.packing_size.unit}}<span class="xiegang">/</span>{{scope.row.packing_size.dose_unit}}</span>
               </template>
             </ux-table-column>
              <ux-table-column field="valid_days" align="center" title="有效天数" width="150" :edit-render="{autofocus: '.ivu-input-number-input'}">
                <template v-slot:edit="scope">
                  <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="scope.row.valid_days"  placeholder="请输入" class="iviewIptWidth100 marginRight18 onlyeight4"></InputNumber>
                </template>
                <template v-slot="scope">
                  <span class="my-input-sc nowrap onlynth">{{ scope.row.valid_days }}</span>
                </template>
             </ux-table-column>
         <ux-table-column width="250" fixed field="describe" align="center" title="操作" :fixed="right">
        <template v-slot="scope">
          <div class="lastDiv">
            <div @click="deleteRow(scope.rowIndex,scope.row)">删除</div>
            <div @click="copyRow(scope.row)">复制</div>
            <div @click="codeReview(scope.row, scope.rowIndex)">条码维护</div>
          </div>
        </template>
      </ux-table-column>
       </ux-grid>
    <!-- <Table :columns="listColumns" :data="listData" border no-data-text="暂无规格型号">
      <template slot-scope="{ row, index }" slot="model_name">
        <i-input placeholder="请输入" type="text" @on-blur="changeIpt($event, 'model_name', index)" :value="row.model_name"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="item_number">
        <i-input placeholder="请输入" @on-blur="changeIpt($event, 'item_number', index)" :value="row.item_number"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="unit_price">
        <InputNumber :active-change="false" :max="9999999" :min="0" style="height:38px;width:100%;"  v-model="row.unit_price" placeholder="请输入" @on-change="changeIpt($event, 'unit_price', index)" ></InputNumber>
     
      </template>
      <template slot-scope="{ row, index }" slot="tax_rate">
        <InputNumber :active-change="false" :max="100" :min="1" style="height:38px;" :precision="0" v-model="row.tax_rate" placeholder="请输入"  @on-change="changeIpt($event, 'tax_rate', index)" ></InputNumber>
      </template>
      <template slot-scope="{row , index  }" slot="unit">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.unit.qty" placeholder="请输入"  @on-change="changeUnitUnit(row.unit.qty, 'unit','qty', index)" class="iviewIptWidth100 marginRight18"></InputNumber>
        <Select class="iviewIptWidth100" placement="top" placeholder="请选择" filterable v-model="row.unit.unit" @on-change="changeUnitUnit(row.unit.unit, 'unit','unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>
        <span class="xiegang">/</span>
        <Select class="iviewIptWidth100" placement="top" placeholder="请选择" filterable v-model="row.unit.dose_unit" @on-change="changeUnitUnit(row.unit.dose_unit, 'unit', 'dose_unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>
        <span class="spin">(单位)</span>
      </template>
      <template slot-scope="{row , index  }" slot="packing_size">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.packing_size.qty" placeholder="请输入"  @on-change="changeUnitUnit(row.packing_size.qty, 'packing_size','qty', index)" class="iviewIptWidth100 marginRight18"></InputNumber>
        <i-input class="iviewIptWidth100" v-model="row.unit.unit" disabled></i-input>
        <span class="xiegang">/</span>
        <Select class="iviewIptWidth100" placeholder="请选择" placement="top" filterable v-model="row.packing_size.dose_unit" @on-change="changeUnitUnit(row.packing_size.dose_unit,'packing_size', 'dose_unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>   
         <span class="spin">(单位)</span>
      </template>
      <template slot-scope="{row , index  }" slot="valid_days">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.valid_days"  placeholder="请输入" @on-change="changeUnitUnit(row.valid_days, 'valid_days','', index)" class="iviewIptWidth100 marginRight18"></InputNumber>
      </template>
    </Table> -->
    <div style="position: relative; border: 1px solid #e8eaec; height: 46px; line-height: 46px;text-align: center; border-top: none !important" class="clearfix" v-if="listData.length == 0">
      暂无数据
    </div>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    条码维护-->
    <code-maintain v-if="codeStatus" :ruleList="ruleList" :master_barcode_identifier="master_barcode_identifier" :master_barcode="master_barcode" :secondary_barcode_rule="secondary_barcode_rule" @closeRule="codeStatus = false" @sureBrn="sureCode"></code-maintain>
    <!--    图片查看-->
    <lookup-image v-if="imgStatus && postFrom.licence_file_url" @closeImg="imgStatus = false" title="注册证" :imgUrl="postFrom.licence_file_url" @></lookup-image>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import newformUpload from '@/components/newformUpload'
import { mapState } from 'vuex'
import TipsComponent from '@/components/tipsComponent'
import CodeMaintain from '@/components/codeMaintain'
import LookupImage from '@/components/lookupImage'
export default {
  name: 'registeCerticate',
  computed: {
    ...mapState(['productInfo'])
  },
  components: {
    FromLabel,
    newformUpload,
    TipsComponent,
    CodeMaintain,
    LookupImage
  },
  data() {
    return {
      rules: {
       model_name: [
                   { required: true, message: '请输入规格型号' }
                ],
                       unit_price: [
                   { required: true, message: '请输入单价' }
                ],
                                tax_rate: [
                   { required: true, message: '请输入税率' }
                ],
      },
      height: 500,
      scrollTop: '',
      right: 'right',
      tableData: [],
      cityList: [
        {
          label: '普通耗材',
          value: '1',
        },
        {
          label: '高值耗材-介入',
          value: '2',
        },
        {
          label: '高值耗材-外科',
          value: '3',
        },
        {
          label: '设备及配件',
          value: '4',
        },
        {
          label: '眼镜类商品',
          value: '5',
        },
        {
          label: '诊断试剂',
          value: '6',
        },
        {
          label: '其他产品',
          value: '7',
        },
      ],
      DeleteModelName: '',
      unitList: [],
      data: {
        table_type: 8
      },
      addRuleIndex: 0,
      ruleList: [],
      master_barcode: '',
      master_barcode_identifier: '',
      secondary_barcode_rule: [],
      imgStatus: false,
      codeStatus: false,
      options: {}, // 有效期至校验
      type: '',
      license: [],
      prolong: false,
      isLoad: false,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          width: '150px',
        },
        {
          title: '子编号',
          key: 'product_code_child',
          align: 'center',
          width: '150px',
        },
        {
          // title: '规格型号',
          renderHeader: (h, param) => {
            return h('span', [
              h(
                'span',
                {
                  style: {
                    color: '#F73333',
                    marginRight: '5px'
                  }
                },
                '*'
              ),
              h('span', '规格型号')
            ])
          },
          slot: 'model_name',
          align: 'center',
          width: '150px',
        },
        {
          title: '货号/SKU',
          slot: 'item_number',
          align: 'center',
          width: '150px',
        },
        {
          // title: '*单价',
          renderHeader: (h, param) => {
            return h('span', [
              h(
                'span',
                {
                  style: {
                    color: '#F73333',
                    marginRight: '5px'
                  }
                },
                '*'
              ),
              h('span', '单价')
            ])
          },
          slot: 'unit_price',
          align: 'center',
          width: '150px',
        },
        {
          // title: '税率(%)',
          renderHeader: (h, param) => {
            return h('span', [
              h(
                'span',
                {
                  style: {
                    color: '#F73333',
                    marginRight: '5px'
                  }
                },
                '*'
              ),
              h('span', '税率(%)')
            ])
          },
          slot: 'tax_rate',
          align: 'center',
          width: '150px',
        },
         {
          title: '不含税单价',
          key: 'notax_unit_price',
          align: 'center',
          width: '150px',
          render: (h,param) => {
            return h('span',
            {
             style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  }, 
            },(param.row.unit_price/(1+param.row.tax_rate/100)).toFixed(4))
          }
        },
        {
          title: '最小包装规格',
          slot: 'unit',
          align: 'center',
          width: '470px',
        },
        {
          title: '包装规格',
          slot: 'packing_size',
          align: 'center',
          width: '470px',
        },
        {
          title: '有效天数',
          slot: 'valid_days',
          align: 'center',
          width: '150px',
        },
        {
          title: '操作',
          align: 'center',
          width: 250,
          fixed: 'right',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.DeleteModelName = param.row.model_name
                      this.deleteRow(param.index)
                    }
                  }
                },
                '删除'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                    marginLeft: '20px'
                  },
                  on: {
                    click: () => {
                      this.copyRow(param.row)
                    }
                  }
                },
                '复制添加'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                    marginLeft: '20px'
                  },
                  on: {
                    click: () => {
                      this.codeReview(param.row, param.index)
                    }
                  }
                },
                '条码维护'
              )
            ])
          }
        }
      ],
      listData: [
        // {
        //   model_name: '',
        //   item_number: '',
        //   unit_price: '',
        //   tax_rate: '13',
        //   master_barcode: '',
        //   master_barcode_identifier: '',
        //   secondary_barcode_rule: [],
        //   notax_unit_price: '',
        //   unit: {qty: 1,unit: '', dose_unit: ''},
        //   packing_size: {qty: 1,unit: '', dose_unit: ''},
        //   valid_days: '',
        // }
      ],
      total: 0,
      pages: {
        page: 1,
        rows: 10
      },
      postFrom: {
        review_id: '',
        licence_code: '',
        licence_file_key: '',
        licence_file_name: '',
        production_address: '',
        creator_company_name: '',
        creator_company_address: '',
        proxy_name: '',
        proxy_address: '',
        comprise_desc: '',
        application_scope: '',
        registrant_time: '',
        approve_dept: '',
        licence_remark: '',
        end_time: '',
        licence_product_code: [],
        licence_product_categories: '',
      },
      skuObj:{
          model_name: '',
          item_number: '',
          unit_price: '',
          tax_rate: '13',
          master_barcode: '',
          master_barcode_identifier: '',
          secondary_barcode_rule: [],
          notax_unit_price: '',
          unit: {qty: 1,unit: '', dose_unit: ''},
          packing_size: {qty: 1,unit: '', dose_unit: ''},
          valid_days: '',
        },
      deleteStatus: false,
      clickIndex: 0,
      details: {},
      is_approved: null,
    }
  },
  created () {
    this.getUnit()
  },
  mounted() { 
    this.$root.$on('productInfo', res => {
      this.details = res
      // 审核通过后不能修改
      this.is_approved = res.is_approved
      let detali = this.details
      if (detali.licence_code) {
        this.postFrom = {
          licence_code: detali.licence_code,
          licence_file_key: detali.licence_file_key,
          licence_file_name: detali.licence_file_name,
          licence_file_url: detali.licence_file_url,
          production_address: detali.production_address,
          creator_company_name: detali.creator_company_name,
          creator_company_address: detali.creator_company_address,
          licence_product_categories: detali.licence_product_categories,
          proxy_name: detali.proxy_name,
          proxy_address: detali.proxy_address,
          comprise_desc: detali.comprise_desc,
          application_scope: detali.application_scope,
          registrant_time: detali.registrant_time ? this.$moment(detali.registrant_time * 1000).format('YYYY-MM-DD') : '',
          approve_dept: detali.approve_dept,
          licence_remark: detali.licence_remark,
          end_time: detali.end_time ? this.$moment(detali.end_time * 1000).format('YYYY-MM-DD') : ''
        }
          this.listData = detali.licence_product_code ? detali.licence_product_code : []
          if(this.listData.length>0){
            this.listData.forEach((item,index)=>{
              if(item.product_model_code){
                this.$set(this.listData[index],'is_approved','1')
              }else {
                this.$set(this.listData[index],'is_approved','0')
              }
            })
          }
        if(detali.flag){
          // 这个说明是同步过来的
          this.listData.forEach((item,index) => {
            // this.$set(this.listData[index],'unit', {qty: 1,unit: '', dose_unit: ''} )
            // this.$set(this.listData[index],'packing_size', {qty: 1,unit: '', dose_unit: ''} )
            // 解决inputNumber默认为1
          if(!this.listData[index].valid_days) {
            this.listData[index].valid_days = ''
          }
          // 转成数字类型
          if(this.listData[index].unit_price) {
            this.listData[index].unit_price = Number(this.listData[index].unit_price)
          }
          })
        }else {
          // 老数据没有最小规格和包装规格 （给默认值
        this.listData.forEach((item,index) => {
          // let obj = detali.unit? JSON.parse(JSON.stringify(detali.unit)) : {qty: 1,unit: '', dose_unit: ''}
          // let obj2 = detali.packing_size? JSON.parse(JSON.stringify(detali.packing_size)) : {qty: 1,unit: '', dose_unit: ''}
          if(!item.unit) {
            this.$set(this.listData[index],'unit', {qty: 1,unit: '', dose_unit: ''} )
          } else {
            // 是不能为null
            for(let key in item.unit) {
              if( item.unit[key] === null ) {
                item.unit = { qty: 1, unit: '', dose_unit: '' }
              }
            }
          }
          if(!item.packing_size) {
            this.$set(this.listData[index],'packing_size', {qty: 1,unit: '', dose_unit: ''} )
          } else {
             // 是不能为null
            for(let key in item.packing_size) {
              if( item.packing_size[key] === null ) {
                item.packing_size = { qty: 1, unit: '', dose_unit: '' }
              }
            }
          }
          // 解决inputNumber默认为1
          if(!this.listData[index].valid_days) {
            this.listData[index].valid_days = ''
          }
          // 转成数字类型
          if(this.listData[index].unit_price) {
            this.listData[index].unit_price = Number(this.listData[index].unit_price)
          }
          
        })
        }
        this.$refs.plxTable.reloadData(this.listData)
      }
    })
    this.queryRuleList()
  },
  methods: {
    changeUnitDose(dose_unit,index){
      if(!dose_unit){
        this.$set(this.listData[index].unit,'dose_unit', '')
      }
    },
    changePacDose(dose_unit,index){
      if(!dose_unit){
        this.$set(this.listData[index].packing_size,'dose_unit', '')
      }
    },
    changeModelnName(e, index) {
      let str = e.target.value.trim()
      this.listData[index].model_name = str
    },
    changeItemNum(e, index) {
      let str = e.target.value.trim()
      this.listData[index].item_number = str
    },
    // 输入框
    notax_unit_priceChange(row,index) {
      this.listData[index].notax_unit_price = (row.unit_price/(1+row.tax_rate/100)).toFixed(4)
    },
    scroll ({scrollTop, scrollLeft}) {
      this.scrollTop = scrollTop
    },
    //     deleteRow (row, index) {
    //   if (this.listData.length !== 0 && this.listData.length !== 1) {
    //     this.DeleteModelName = row.model_name
    //     this.DeleteIndex = index
    //     this.deleteStatus = true
    //   } else if (this.listData.length === 1) {
    //     this.$Message.warning('当前产品只有一个规格，不可删除!')
    //   }
    // },
    handleCreateUnit(val) {
      this.unitList.push({
        value: val.trim(),
        label: val.trim()
      })
    },
    openChangeUnit(status, obj, name, refName) {
      // if (!status && !this.listData[obj][name]) {
      //   this.$refs[refName].query = ''
      // }
    },
    changeUnitUnit(val,key,childKey,index) {
      if (val === null || !val || val === undefined) {
        val = ''
      }
      if (key == 'unit' || key == 'packing_size') {
        this.$set(this.listData[index][key],childKey , val)
        if(childKey == 'unit') {
        this.$set(this.listData[index]['packing_size'],childKey , val)  
        }
      } else if(key == 'valid_days') {
        this.$set(this.listData[index], key , val)
      }
    },
    getUnit() {
      this.$http.get(this.$api.productUnit, null, false).then(res => {
        res.data.forEach(item => {
          let obj = {
            value: item.unit,
            label: item.unit
          }
          this.unitList.push(obj)
        })
        if (this.unitList.length > 0) {
          this.unitList = [...new Set([...this.unitList])]
        }
      })
    },
    changeNum(e) {
      this.$nextTick(() => {
        if (e.target.value.indexOf('/') > -1) {
          let str = ''
          let arr = e.target.value.split('/')
          for (let i = 0; i < arr.length; i++) {
            str += arr[i]
          }
          this.postFrom.licence_code = str
          this.postFrom.licence_file_name = this.postFrom.licence_code
        }
        this.postFrom.licence_file_name = this.postFrom.licence_code
      })
    },
    delectImg() {
      this.postFrom.licence_file_key = ''
      this.postFrom.licence_file_name = ''
      this.postFrom.licence_file_url = ''
    },
    queryRuleList() {
      this.$http.get(this.$api.productCodeRule, this.queryFrom, true).then(res => {
        this.ruleList = res.data
      })
    },
    openFile() {
      window.open(this.postFrom.licence_file_url)
    },
    sureCode(master_barcode_identifier, master_barcode, secondary_barcode_rule) {
      this.codeStatus = false
      this.$set(this.listData[this.addRuleIndex], 'master_barcode_identifier', master_barcode_identifier)
      this.$set(this.listData[this.addRuleIndex], 'master_barcode', master_barcode)
      this.$set(this.listData[this.addRuleIndex], 'secondary_barcode_rule', secondary_barcode_rule)
    },
    // 条码维护
    codeReview(row, index) {
      this.master_barcode_identifier = row.master_barcode_identifier
      this.master_barcode = row.master_barcode
      this.secondary_barcode_rule = row.secondary_barcode_rule ? row.secondary_barcode_rule : []
      this.addRuleIndex = index
      this.codeStatus = true
    },
    // 复制添加
    copyRow(row) {
      let obj = {
        model_name: row.model_name,
        item_number: row.item_number,
        unit_price: row.unit_price,
        tax_rate: row.tax_rate,
        product_model_code: '',
        master_barcode: row.master_barcode,
        master_barcode_identifier: row.master_barcode_identifier,
        secondary_barcode_rule: row.secondary_barcode_rule,
        notax_unit_price: row.notax_unit_price,
        unit: row.unit,
        packing_size: row.packing_size,
        valid_days: row.valid_days,
      }
      this.$set(this.listData, this.listData.length, JSON.parse(JSON.stringify(obj)))
      this.$refs.plxTable.reloadData(this.listData)
    },
    // 确认删除
    sureDelete() {
      let arr =[]
      if(!this.DeleteModelName || !this.$route.query.id) {
          arr = this.listData
          this.listData.splice(this.clickIndex, 1)
          // this.listData = this.listData ? JSON.parse(JSON.stringify(arr)) : []
          this.listData = JSON.parse(JSON.stringify(this.listData))
          this.$refs.plxTable.reloadData(this.listData)
          this.$Message.success('删除成功')
          this.deleteStatus = false
          return
      }
      this.$http.post(this.$api.productVerifyProductCode, { model_name: this.DeleteModelName, review_id: this.$route.query.id }, true).then(res => {
        if (res.status) {
          arr = this.listData
          this.listData.splice(this.clickIndex, 1)
          this.listData = this.listData ? JSON.parse(JSON.stringify(arr)) : []
          this.$refs.plxTable.reloadData(arr)
          this.$refs.plxTable.pagingScrollTopLeft(this.scrollTop, 0)
          this.$Message.success('删除成功')
        } else {
          this.$Message.warning('当前规格型号已发生业务往来，不允许删除')
        }
        this.deleteStatus = false
      })
    },
    // 点击删除按钮
    deleteRow(index,row) {
      this.DeleteModelName = row.model_name
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if(typeof e == 'object') {
      if (name == 'unit_price' || name == 'tax_rate') {
        if (e.target.value * 1 < 0) {
          this.$set(this.listData[index], name, '1')
          return
        } else {
          this.$set(this.listData[index], name, e.target.value)
          return
        }
      }
      this.$set(this.listData[index], name, e.target.value)
      } else {
      if (name == 'unit_price' || name == 'tax_rate') {
        if (e * 1 < 0) {
          this.$set(this.listData[index], name, '1')
          return
        } else {
          this.$set(this.listData[index], name, e)
          return
        }
      }
        this.$set(this.listData[index], name, e)
      }

    },
    // 新增规格型号
    addSku() {
      // return
      // this.listData = []
      this.$set(this.listData, this.listData.length, JSON.parse(JSON.stringify(this.skuObj)))
      this.$refs.plxTable.reloadData(this.listData)
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.postFrom.registrant_time = e
        that.postFrom.end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.postFrom.registrant_time)
          }
        }
      } else {
        this.postFrom.end_time = e
      }
    },
    // 上传注册证
    getKey(key, name, url) {
      this.postFrom.licence_file_key = key
      this.postFrom.licence_file_name = name
      this.postFrom.licence_file_url = url
    },
    // 新增规格型号
    addBill() {
      this.$Message.warning('功能开发中...')
    },
    deleteBill() {},
    // 模板导入
    importTemplate() {
      this.$Message.warning('功能开发中...')
    },
    // 模板下载
    downloadTemplate() {
      this.$Message.warning('功能开发中...')
    }
  }
}
</script>

<style scoped lang="less">
  .my-input-sc {
    display: inline-block;
    line-height: 36px;
    height: 36px;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    outline: none;
    margin-top: 6px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    white-space: pre;
  }
.textLeft{
  display: inline-block;
  width: 100%;
  text-align: left;
  padding-left: 5px;
}
/deep/ .ivu-table-overflowX{
  padding-bottom: 30px;
}
/deep/ .ivu-table-wrapper{
  overflow: initial;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight18 {
  margin-right: 18px;
}
.tableTitle {
  position: relative;
  flex-wrap: no-wrap;
  display: flex;
  align-items: center;
  margin: 17px 0 20px 0;
  justify-content: space-between;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.spin {
  margin-left: 20px;
  font-size: 14px;
  color: #525b6d;
}
.iviewIptWidth100 {
  width:75px;
  height: 38px;
  line-height: 38px;
}
@keyframes mymove
{
0% {
// background:red;
transform: translateX(0%);  
}
10% 
{
  // background:yellow;
transform: translateX(-5%);  
}
20% 
{
  // background:yellow;
transform: translateX(-10%);  
}
30% 
{
  // background:yellow;
transform: translateX(-15%);  
}
40% 
{
  // background:yellow;
transform: translateX(-20%);  
}
50% 
{
  // background:yellow;
transform: translateX(-25%);  
}
100% 
{
  // background:green;
transform: translateX(-30%);  
}
70% 
{
  // background:green;
transform: translateX(-35%);  
}
80% 
{
  // background:green;
transform: translateX(-40%);  
}
90% 
{
  // background:green;
transform: translateX(-45%);  
}
100% 
{
  // background:green;
transform: translateX(-50%);  
}
}
.remarksAlink
{
padding-top: 5px;
overflow: hidden;
display: inline-block;
animation:mymove  50s infinite;
animation-timing-function: linear;
}
.remarksAlink:hover{
  animation-play-state: paused
}
.overflow{
  margin-left: 20px;
  word-wrap: normal;
  flex: 1;
  overflow: hidden;
}
.over {
  width: 6000px;
}
.marginL{
  margin-left: 20px;
}
.over:hover {
  cursor: pointer;
}
/deep/ .ivu-input-number-handler-wrap{
  display: none !important;
}
.lastDiv {
  display: flex;
  justify-content: space-between;
  div {
    margin: 0 10px;
    color: #389AFC;
    cursor: pointer;
  }
}
.bludColor{
  color: #389AFC;
}
/deep/ .elx-table .elx-body--column.col--valid-error .elx-cell--valid{
  display: none !important;
}
</style>
